import {METADATA} from "../../helpers/metadata";
import {GET_POST_DETAIL, GET_POSTS} from "../actionTypes";

const initialState = {
  posts: { ...METADATA.default, data: null },
  post: { ...METADATA.default, data: null },
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSTS.REQUEST:
      return { ...state, posts: { ...state.posts, ...METADATA.request }};
    case GET_POSTS.SUCCESS:
      return { ...state, posts: { ...state.posts, ...METADATA.success, data: action.payload }};
    case GET_POSTS.FAILURE:
      return { ...state, posts: { ...state.posts, ...METADATA.error, error: action.error }};
    case GET_POST_DETAIL.REQUEST:
      return { ...state, post: { ...state.post, ...METADATA.request, data: null }};
    case GET_POST_DETAIL.SUCCESS:
      return { ...state, post: { ...state.post, ...METADATA.success, data: action.payload }};
    case GET_POST_DETAIL.FAILURE:
      return { ...state, post: { ...state.post, ...METADATA.error, error: action.error }};

    default:
      return state;
  }
};

export default postReducer;