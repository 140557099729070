import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import "./index.scss";

const ShareButtons = ({url}) => (
  <div className="share_buttons">
    <FacebookShareButton url={url} >
        <span>
          <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.84468 17V8.499H7.00008L7.28571 5.56951H4.84468L4.84834 4.10328C4.84834 3.33922 4.91502 2.92982 5.92299 2.92982H7.27046V0H5.11475C2.52541 0 1.61403 1.42112 1.61403 3.81099V5.56985H0V8.49934H1.61403V17H4.84468Z" fill="#9E98A3"/>
          </svg>
        </span>
    </FacebookShareButton>
    <LinkedinShareButton url={url} >
        <span>
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.83508 6.80664H0.910156V17.0439H3.83508V6.80664Z" fill="#9E98A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.56707 3.14899C4.54176 1.90418 3.72017 0.956055 2.38603 0.956055C1.0519 0.956055 0.179688 1.90418 0.179688 3.14899C0.179688 4.36801 1.02612 5.34344 2.33541 5.34344H2.36033C3.72017 5.34344 4.56707 4.36801 4.56707 3.14899Z" fill="#9E98A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9997 10.8988C16.9997 7.60718 15.2272 6.0752 12.863 6.0752C10.9554 6.0752 10.1014 7.11535 9.62452 7.84503V6.32712H6.03125C6.07861 7.33271 6.03125 17.0436 6.03125 17.0436H9.62452V11.0587C9.62452 10.7384 9.64784 10.4189 9.74284 10.1896C10.0025 9.54971 10.5937 8.88724 11.5863 8.88724C12.887 8.88724 13.4069 9.86992 13.4069 11.31V17.0434H16.9996L16.9997 10.8988Z" fill="#9E98A3"/>
          </svg>
        </span>
    </LinkedinShareButton>
    <TwitterShareButton url={url} >
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
            <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" fill="#9E98A3" />
          </svg>
        </span>
    </TwitterShareButton>
  </div>
);

export default ShareButtons;