import qs from 'qs';
import {DATA_FORMAT_DD_MMMM_YYYY, STATIC_RELATIVE_PATH_REGEX} from "./constants";
import config from "../config";
import moment from "moment";

export const getQuery = (params, exclude = []) => {
  let datasets = '';
  if (params) {
    const filteredParams = { ...params, search: params.search ? params.search : null };
    const excludeList = [...exclude, 'hasMore'];
    excludeList.map(key => delete filteredParams[key]);
    datasets = qs.stringify({ ...filteredParams }, { strictNullHandling: true, skipNulls: true });
    if (datasets) {
      return `?${datasets}`;
    }
  }
  return '';
};

export const getMessage = (res, customMsg) => {
  return (res && res.message) || customMsg || "Something went wrong, please try again";
};

export const URLtoAbsolutePath = url => STATIC_RELATIVE_PATH_REGEX.test(url) ? config.baseURL + url : url;
export const getPrettyDate = (data, format = DATA_FORMAT_DD_MMMM_YYYY) => moment(data).format(format);


function pos(str, char) {
  let pos = 0
  const ret = []
  while ( (pos = str.indexOf(char, pos + 1)) != -1) {
    ret.push(pos)
  }
  return ret
}

export const truncate = (str, len) => {
  if (str.length < len)
    return str

  const allPos = [  ...pos(str, '!'), ...pos(str, '.'), ...pos(str, '?')].sort( (a,b) => a-b )
  if (allPos.length === 0) {
    return str.substr(0, len) + '...'
  }

  for(let i = 0; i < allPos.length; i++) {
    if (allPos[i] > len) {
      return str.substr(0, allPos[i-1] + 1)  + '...'
    }
  }
}