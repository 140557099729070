import React from "react";
import {Link} from "react-router-dom";
import nl2br from 'react-nl2br';
import classnames from "classnames";
import moment from "moment";
import {DATA_FORMAT_DD_MMMM_YYYY} from "../../../helpers/constants";
import {truncate} from "../../../helpers/utils";
import "./index.scss";

const HorizontalPostCard = ({post, className}) => {
  const {author, date, main_image, slug, subtitle, title, description} = post;
  return (
    <Link to={`/${slug}`} className={classnames("horizontal_post_card", className)}>
      <div className="horizontal_post_card__inner">
        <div className="horizontal_post_card__image">
          <img src={main_image} alt={title} />
        </div>
        <div className="horizontal_post_card__content">
          <div className="horizontal_post_card__content_top">
            <div className="horizontal_post_card__content_type f-20-30 f-400">{subtitle}</div>
            <h2 className="horizontal_post_card__content_title f-40-50 f-400">{title}</h2>
            <p className="horizontal_post_card__content_desc f-20-30 f-400">{nl2br(truncate(description, 260))}</p>
          </div>
          <div className="horizontal_post_card__content_bottom f-15-22">
            <div>{author ? author : "Editors at Scramble"}</div>
            <div>{moment(date).format(DATA_FORMAT_DD_MMMM_YYYY)}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default HorizontalPostCard;