import {useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import PageHelmet from "./components/PageHelmet";
import {useDispatch, useSelector} from "react-redux";
import {updateResolution} from "./store/actions/appAction";
import Header from "./promo/Header/Header";
import Footer from "./promo/Footer/Footer";
import PostPage from "./pages/PostPage";
import PostDetailPage from "./pages/PostDetailPage";
import Page404 from "./pages/404";

function App() {
  const dispatch = useDispatch();
  const {isMobile, innerWidth} = useSelector(state => state.appStore);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {window.removeEventListener('resize', onResize)};
  }, []);

  const onResize = () => {
    dispatch(updateResolution(window.innerWidth));
  }

  return (
    <>
      <PageHelmet />
      <Header mobile={innerWidth < 700} />
      <main>
        <Routes>
          <Route path={'/'} element={<PostPage />} />
          <Route path={'/404'} element={<Page404 />} />
          <Route path={'/:postSlug'} element={<PostDetailPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </main>
      <Footer mobile={isMobile} />
    </>
  );
}

export default App;