import React from 'react';
import {MyLink} from "../../components/MyLink";
import {LINKS} from "../../helpers/constants";
import './index.scss';

const Page404 = () => (
  <div className="page_404">
    <div className="container">
      <div className="page_404__wrapper">
        <div className="page_404__content">
          404 - Page not found<br />
          You can go to the <MyLink href={LINKS.main} isExternal>Homepage</MyLink> or read our <MyLink href={'/'}>latest posts in Blog</MyLink>.
        </div>
      </div>
    </div>
  </div>
);

export default Page404;