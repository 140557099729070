import React from 'react';
import {Helmet} from 'react-helmet-async';

const DEFAULTS = {
  title: 'Scramble',
  description: 'High-yield passive income for busy professionals',
  image: '/scramble_img.png',
  url: 'https://scrambleup.com/'
}

const PageHelmet = props => {

  const {title, description, url, image} = DEFAULTS;

  return (
    <Helmet>
      {/*  Primary Meta Tags */}
      <title>{props.title || title}</title>
      <meta name="title" content={props.title || title} />
      <meta name="description" content={props.description || description} />

      {/*  Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title || title} />
      <meta property="og:description" content={props.description || description} />
      <meta property="og:image" content={props.image || image} />
      <meta property="og:url" content={props.url || url} />
      <meta property="fb:app_id" content="290462042858178" />

      {/*  Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={props.url || url} />
      <meta property="twitter:title" content={props.title || title} />
      <meta property="twitter:description" content={props.description || description} />
      <meta property="twitter:image" content={props.image || image} />

      {/* Google+ meta tags */}
      <meta itemProp="name" content={props.title || title} />
      <meta itemProp="description" content={props.description || description} />
      <meta itemProp="image" content={props.image || image} />
      <meta itemProp="url" content={props.url || url} />}
    </Helmet>
  )
}

export default PageHelmet;