import axios from "../../axios-api";
import Pathes from "../../helpers/pathes";
import {GET_POST_DETAIL, GET_POSTS} from "../actionTypes";
import {getMessage, getQuery} from "../../helpers/utils";

const getPostsRequest = () => ({ type: GET_POSTS.REQUEST });
const getPostsSuccess = payload => ({type: GET_POSTS.SUCCESS, payload});
const getPostsFailure = error => ({type: GET_POSTS.FAILURE, error});

const getPostDetailRequest = () => ({ type: GET_POST_DETAIL.REQUEST });
const getPostDetailSuccess = payload => ({type: GET_POST_DETAIL.SUCCESS, payload});
const getPostDetailFailure = error => ({type: GET_POST_DETAIL.FAILURE, error});

export const getPosts = (params, isNext) => {
  return (dispatch, getState) => {
    dispatch(getPostsRequest());
    return axios.get(Pathes.Posts.list + getQuery(params)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          const prevData = getState().postStore.posts.data;
          if (!isNext || !prevData) {
            dispatch(getPostsSuccess(data));
            return {data, success: true};
          }

          const updatedData = {
            total_count: data.total_count,
            total_pages: data.total_pages,
            list: [ ...prevData.list, ...data.list]
          }

          dispatch(getPostsSuccess(updatedData));
          return {data: updatedData, success: true};
        }
        throw new Error(message)
      }).catch(e => dispatch(getPostsFailure(e.message)));
  };
}

export const getPostDetail = postSlug => {
  return dispatch => {
    dispatch(getPostDetailRequest());
    return axios.get(Pathes.Posts.detail(postSlug)).then(
      response => {
        const {status, data} = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getPostDetailSuccess(data));
          return {data, success: true};
        }

        // Post not found, redirect to 404 page
        if (status === 404) {
          return {success: false, status};
        }

        throw new Error(message)
      }).catch(e => dispatch(getPostDetailFailure(e.message)));
  };
}