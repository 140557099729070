import {UPDATE_RESOLUTION} from "../actionTypes";

export const updateResolution = width => {
  return dispatch => {
    const payload = {
      isMobile: width <= 414,
      isTablet: width > 414 && width <= 1024,
      isDesktop: width > 1024,
      innerWidth: width,
    };
    dispatch({type: UPDATE_RESOLUTION, payload});
  }
}