import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import withScroll from "../../hoc/withScroll";
import HorizontalPostCard from "../../components/cards/HorizontalPostCard";
import VerticalPostCard from "../../components/cards/VerticalPostCard";
import {DEFAULT_LIMIT} from "../../helpers/constants";
import {getPosts} from "../../store/actions/postAction";
import './index.scss';

const PostPage = () => {
  const dispatch = useDispatch();
  const fetchedData = useSelector(state => state.postStore.posts);
  const {innerWidth} = useSelector(state => state.appStore);
  const [state, setState] = useState({
    page: 1,
    limit: DEFAULT_LIMIT
  });

  const {data: posts} = fetchedData;

  const isEnoughPosts = posts && posts.total_count > 3;

  useEffect(() => {
    dispatch(getPosts(state));
  }, []);

  const getNext = async totalPages => {
    if (state.page < totalPages) {
      const nextPage = state.page + 1;
      const res = await dispatch(getPosts({...state, page: nextPage}, true));
      return res && res.success && setState({...state, page: nextPage});
    }
  }

  return (
    <div className="post_page">
      <div className="container">
        <div className="post_page__list">
          {posts && !!posts.total_count && (
            <>
              {(isEnoughPosts && innerWidth >= 800) ? (
                <>
                  {innerWidth <= 414 ? (
                    <VerticalPostCard
                      post={posts.list[0]}
                    />
                  ) : (
                    <HorizontalPostCard
                      post={posts.list[0]}
                      className="post_page__card_horizontal"
                    />
                  )}

                  <div className="post_page__grid">
                    {posts.list.map((post, index) => index !== 0 && (
                      <VerticalPostCard
                        post={post}
                        key={post.id}
                      />
                    ))}
                  </div>
                </>
              ) : posts.list.map(post => innerWidth >= 800 ? (
                <HorizontalPostCard
                  key={post.id}
                  post={post}
                  className="post_page__card_horizontal"
                />
              ) : (
                <VerticalPostCard
                  post={post}
                  key={post.id}
                />
              ))}
            </>
          )}

          {posts && state.page < posts.total_pages && (
            <div className="post_page__buttons">
              <button type="button" onClick={() => getNext(posts.total_pages)} className="post_page__buttons_all f-20-24">
                View more articles
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withScroll(PostPage);