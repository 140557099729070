import {UPDATE_RESOLUTION} from "../actionTypes";

const initialState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  innerWidth: window.innerWidth,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RESOLUTION:
      return {...state, ...action.payload};

    default:
      return state;
  }
};

export default appReducer;