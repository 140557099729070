import config from "../config";

export const CONTACT_EMAIL = 'ask@scrambleup.com';
export const DEFAULT_LIMIT = 20;
export const DATA_FORMAT_DD_MMMM_YYYY = 'D MMMM YYYY';
export const STATIC_RELATIVE_PATH_REGEX = /^\/media\//;

export const LINKS = {
  main: config.parentDomain,
  forInvestors: config.parentDomain,
  forFounders: config.foundersPromo,
  product: `${config.parentDomain}/product`,
  howItWorks: `${config.parentDomain}/how-it-works`,
  about: `${config.parentDomain}/about`,
  contactUs: `${config.parentDomain}/contacts`,
  help: `${config.parentDomain}/help`,
  termsOfUse: `${config.parentDomain}/docs/Terms_of_use.pdf`,
  privacyAndPolicy: `${config.parentDomain}/docs/Privacy_policy.pdf`,
  signIn: `${config.investorApp}/sign-in`,
  getStarted: `${config.investorApp}/sign-up`,
  blog: `/`,
}