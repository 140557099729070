import React from 'react';
import classnames from 'classnames';
import nl2br from 'react-nl2br';
import moment from "moment";
import {Link} from "react-router-dom";
import {DATA_FORMAT_DD_MMMM_YYYY} from "../../../helpers/constants";
import {truncate} from "../../../helpers/utils";
import './index.scss';

const VerticalPostCard = ({post, className}) => {
  const {author, date, slug, main_image, subtitle, title, description} = post;
  return (
    <Link to={`/${slug}`} className={classnames("vertical_post_card", className)}>
      <div className="vertical_post_card__inner">
        <div className="vertical_post_card__image">
          <img src={main_image} alt={title} />
        </div>
        <div className="vertical_post_card__content">
          <div className="vertical_post_card__content_type f-20-30 f-400">{subtitle}</div>
          <h2 className="vertical_post_card__content_title f-30-40 f-400">{title}</h2>
          <p className="vertical_post_card__content_desc f-20-30 f-400">{nl2br(truncate(description, 250))}</p>
        </div>
        <div className="vertical_post_card__content_bottom f-15-22">
          <div>{author ? author : "Editors at Scramble"}</div>
          <div>{moment(date).format(DATA_FORMAT_DD_MMMM_YYYY)}</div>
        </div>
      </div>
    </Link>
  );
};

export default VerticalPostCard;