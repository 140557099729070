import React from "react"
import { Link } from "react-router-dom"

import footerLogo from "../../footerLogo.svg"
import "./mobFooter.scss"
import {MyLink} from "../../../components/MyLink";
import {CONTACT_EMAIL, LINKS} from "../../../helpers/constants";

const MobFooter = ({ currentYear, onClickFooterNavItems }) => {
  return (
    <footer className="mobFooter">
      <div className="wrapper">
        <div className="mainPart">
          <div className="col1 body-text text-white">
            <div className="mainPartRow1">
              <MyLink href={LINKS.main} isExternal newWindow={false}>
                <img src={footerLogo} alt="footerLogo" />
              </MyLink>
            </div>
            <div className="mainPartRow2">
              <MyLink
                className="email body-text text-white"
                href={`mailto:${CONTACT_EMAIL}`}
                isExternal newWindow={false}
              >
                {CONTACT_EMAIL}
              </MyLink>
            </div>
            <div className="mainPartRow3">
              <div className="facebook">
                <MyLink
                  className="footer_facebook"
                  href="https://www.facebook.com/ScrambleUpCom"
                  isExternal newWindow={false}
                >
                  <svg
                    width="8"
                    height="17"
                    viewBox="0 0 8 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.84468 17V8.499H7.00008L7.28571 5.56951H4.84468L4.84834 4.10328C4.84834 3.33922 4.91502 2.92982 5.92299 2.92982H7.27045V0H5.11475C2.52541 0 1.61403 1.42112 1.61403 3.81099V5.56985H0V8.49934H1.61403V17H4.84468Z"
                      fill="black"
                    />
                  </svg>
                </MyLink>
              </div>
              <div className="linkedIn">
                <MyLink
                  className="footer_linkedIn"
                  href="https://www.linkedin.com/company/scrambleup"
                  isExternal newWindow={false}
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.65637 5.85022H0.731445V16.0874H3.65637V5.85022Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.38738 2.19293C4.36207 0.948122 3.54048 0 2.20635 0C0.872211 0 0 0.948122 0 2.19293C0 3.41196 0.846432 4.38738 2.15572 4.38738H2.18064C3.54048 4.38738 4.38738 3.41196 4.38738 2.19293Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.8181 9.94248C16.8181 6.65082 15.0456 5.11884 12.6813 5.11884C10.7738 5.11884 9.91974 6.15899 9.44288 6.88867V5.37076H5.84961C5.89696 6.37635 5.84961 16.0873 5.84961 16.0873H9.44288V10.1023C9.44288 9.78201 9.4662 9.46252 9.5612 9.2332C9.82086 8.59335 10.4121 7.93088 11.4047 7.93088C12.7053 7.93088 13.2252 8.91356 13.2252 10.3537V16.087H16.8179L16.8181 9.94248Z"
                      fill="black"
                    />
                  </svg>
                </MyLink>
              </div>
              <div className="insta">
                <MyLink
                  className="footer_insta"
                  href="https://instagram.com/scramble.ppl"
                  isExternal newWindow={false}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30"
                    width="30"
                    viewBox="-100.7682 -167.947 873.3244 1007.682"
                  >
                    <g fill="#fff">
                      <path d="M335.895 0c-91.224 0-102.663.387-138.49 2.021-35.752 1.631-60.169 7.31-81.535 15.612-22.088 8.584-40.82 20.07-59.493 38.743-18.674 18.673-30.16 37.407-38.743 59.495C9.33 137.236 3.653 161.653 2.02 197.405.386 233.232 0 244.671 0 335.895c0 91.222.386 102.661 2.02 138.488 1.633 35.752 7.31 60.169 15.614 81.534 8.584 22.088 20.07 40.82 38.743 59.495 18.674 18.673 37.405 30.159 59.493 38.743 21.366 8.302 45.783 13.98 81.535 15.612 35.827 1.634 47.266 2.021 138.49 2.021 91.222 0 102.661-.387 138.488-2.021 35.752-1.631 60.169-7.31 81.534-15.612 22.088-8.584 40.82-20.07 59.495-38.743 18.673-18.675 30.159-37.407 38.743-59.495 8.302-21.365 13.981-45.782 15.612-81.534 1.634-35.827 2.021-47.266 2.021-138.488 0-91.224-.387-102.663-2.021-138.49-1.631-35.752-7.31-60.169-15.612-81.534-8.584-22.088-20.07-40.822-38.743-59.495-18.675-18.673-37.407-30.159-59.495-38.743-21.365-8.302-45.782-13.981-81.534-15.612C438.556.387 427.117 0 335.895 0zm0 60.521c89.686 0 100.31.343 135.729 1.959 32.75 1.493 50.535 6.965 62.37 11.565 15.68 6.094 26.869 13.372 38.622 25.126 11.755 11.754 19.033 22.944 25.127 38.622 4.6 11.836 10.072 29.622 11.565 62.371 1.616 35.419 1.959 46.043 1.959 135.73 0 89.687-.343 100.311-1.959 135.73-1.493 32.75-6.965 50.535-11.565 62.37-6.094 15.68-13.372 26.869-25.127 38.622-11.753 11.755-22.943 19.033-38.621 25.127-11.836 4.6-29.622 10.072-62.371 11.565-35.413 1.616-46.036 1.959-135.73 1.959-89.694 0-100.315-.343-135.73-1.96-32.75-1.492-50.535-6.964-62.37-11.564-15.68-6.094-26.869-13.372-38.622-25.127-11.754-11.753-19.033-22.943-25.127-38.621-4.6-11.836-10.071-29.622-11.565-62.371-1.616-35.419-1.959-46.043-1.959-135.73 0-89.687.343-100.311 1.959-135.73 1.494-32.75 6.965-50.535 11.565-62.37 6.094-15.68 13.373-26.869 25.126-38.622 11.754-11.755 22.944-19.033 38.622-25.127 11.836-4.6 29.622-10.072 62.371-11.565 35.419-1.616 46.043-1.959 135.73-1.959" />
                      <path d="M335.895 447.859c-61.838 0-111.966-50.128-111.966-111.964 0-61.838 50.128-111.966 111.966-111.966 61.836 0 111.964 50.128 111.964 111.966 0 61.836-50.128 111.964-111.964 111.964zm0-284.451c-95.263 0-172.487 77.224-172.487 172.487 0 95.261 77.224 172.485 172.487 172.485 95.261 0 172.485-77.224 172.485-172.485 0-95.263-77.224-172.487-172.485-172.487m219.608-6.815c0 22.262-18.047 40.307-40.308 40.307-22.26 0-40.307-18.045-40.307-40.307 0-22.261 18.047-40.308 40.307-40.308 22.261 0 40.308 18.047 40.308 40.308" />
                    </g>
                  </svg>
                </MyLink>
              </div>
            </div>
            <div className="mainPartRow4">
              <p className="body-text text-white">
                © {currentYear}
                , Scramble
                <br />
                OÜ. All Rights
                <br />
                Reserved.
              </p>
            </div>
          </div>
          <div className="col2 body-text text-white">
            <nav className="mobNav">
              <ul>
                <li className="active">
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.forInvestors}
                    isExternal newWindow={false}
                  >
                    For investors
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    className="grey body-text text-white"
                    href={LINKS.forFounders}
                    isExternal newWindow={false}
                  >
                    For founders
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.product}
                    isExternal newWindow={false}
                  >
                    Product
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.howItWorks}
                    isExternal newWindow={false}
                  >
                    How it works
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.about}
                    isExternal newWindow={false}
                  >
                    About
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.blog}
                  >
                    Blog
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.contactUs}
                    isExternal newWindow={false}
                  >
                    Contact us
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.help}
                    isExternal newWindow={false}
                  >
                    Help
                  </MyLink>
                </li>

                <p className="text-white body-text">Legal</p>
                <li className="grey">
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.termsOfUse}
                    isExternal
                  >
                    Terms of Use
                  </MyLink>
                </li>
                <li className="grey">
                  <MyLink
                    className="body-text text-white"
                    href={LINKS.privacyAndPolicy}
                    isExternal
                  >
                    Privacy Policy
                  </MyLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <hr />
        <div className="aboutCompanyText">
          <p className="text body-text text-white">
            Scramble Platform (“Scramble OÜ”) is a private limited company
            registered in the Commercial Register of Estonia under registration
            No. 14991448, with legal address at Pärnu mnt 22 Kesklinna linnaosa,
            Harju maakond 10141, Tallinn, Estonia. Scramble is not regulated
            under any financial services license. Scramble is a global online
            lending platform. Investment through Scramble involves lending to
            small businesses, consequently your capital may be at risk. Scramble
            does not guarantee that Investor will get the investment back or
            receive interest. We advise to carefully evaluate the risks.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default MobFooter
