export const UPDATE_RESOLUTION = 'UPDATE_RESOLUTION';

export const GET_POSTS = {
  REQUEST: 'GET_POSTS_REQUEST',
  SUCCESS: 'GET_POSTS_SUCCESS',
  FAILURE: 'GET_POSTS_FAILURE',
}

export const GET_POST_DETAIL = {
  REQUEST: 'GET_POST_DETAIL_REQUEST',
  SUCCESS: 'GET_POST_DETAIL_SUCCESS',
  FAILURE: 'GET_POST_DETAIL_FAILURE',
}