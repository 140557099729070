import * as React from 'react';
import withScroll from "../../hoc/withScroll";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPostDetail} from "../../store/actions/postAction";
import {getPrettyDate} from "../../helpers/utils";
import ShareButtons from "../../components/ShareButtons";
import PageHelmet from "../../components/PageHelmet";
import {MyLink} from "../../components/MyLink";
import config from "../../config";
import './index.scss';

const PostDetailPage = () => {
  const navigate = useNavigate();
  const {postSlug} = useParams();
  const dispatch = useDispatch();
  const {post} = useSelector(state => state.postStore);

  useEffect(() => {
    getPost();
  }, []);

  const getPost = async () => {
    const res = await dispatch(getPostDetail(postSlug));
    res && !res.success && res.status === 404 && navigate('/404', { replace: true });
  }

  if (post.loading) {
    return null;
  }

  if (!post.data || (!post.data && !post.loading)) {
    return (
      <div className="post_detail_page">
        <div className="container">
          <div className="post_detail_page__error">
            <div className="post_detail_page__error_content">
              Couldn't load article content.<br />
              Please check network and try again
            </div>
          </div>
        </div>
      </div>
    );
  }

  const {title, subtitle, date, description, main_image, slug, author, header_image, text} = post.data;

  return (
    <div className="post_detail_page">
      {post && (
        <PageHelmet
          title={title}
          description={description}
          image={main_image}
          url={config.baseURL + `/${slug}`}
        />
      )}

      <div className="post_detail_page__background" style={{backgroundImage: `url("${header_image}")`}} />
      <div className="container">
        <div className="post_detail_page__content" >
          <div className="post_detail_page__content_header">
            <div className="post_detail_page__content_type f-20-30">{subtitle}</div>
            <h1 className="post_detail_page__content_title f-40-50">{title}</h1>
            <div className="post_detail_page__content_author f-20-30">
              <div>Written by {author}</div>
              <div>On {getPrettyDate(date)}</div>
            </div>
          </div>
          <div className="post_detail_page__content_inner">
            <div className="post_detail_page__content_share">
              <ShareButtons url={config.baseURL + `/${slug}/`} />
            </div>
            <div className="post_detail_page__content_text unreset" dangerouslySetInnerHTML={{ __html: text }}/>
          </div>
        </div>

        <div className="post_detail_page__buttons">
          <MyLink className="post_detail_page__buttons_more" href='/'>
            View more articles
          </MyLink>
        </div>
      </div>
    </div>
  );
}

export default withScroll(PostDetailPage);